<template>
  <div class="result">
    <transition name="van-slide-down">
      <section v-show="visible" class="icon">
        <img :src="require('../assets/success.svg')" alt="" />
      </section>
    </transition>
    <transition name="van-slide-up">
      <section v-show="visible">
        <h1>提交成功</h1>
        <h2>已向您的邮箱发送了一封确认邮件，<br />请注意查收。</h2>
      </section>
    </transition>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.visible = true;
    }, 300);
  },
});
</script>

<style scoped>
.result {
  margin-top: 20vh;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
h1, h2 {
  text-align: center;
  color: #888;
  font-weight: 400;
}
h2 {
  font-size: 16px;
}
@media (min-width: 800px) {
  .result {
    padding-bottom: 20vh;
  }
}
</style>
