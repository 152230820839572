
import { defineComponent } from 'vue';

export default defineComponent({
  data() {
    return {
      visible: false,
    };
  },
  mounted() {
    setTimeout(() => {
      this.visible = true;
    }, 300);
  },
});
